import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
import {getTranslations, prepareConfig} from "./core/services/i18n.Api.Service";
import {VERIFY_AUTH} from "./core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import filter from "@/core/filter/index";
import mixin from "@/core/mixin/index";
import InfiniteLoading from 'vue-infinite-loading';
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
// Vue 3rd party plugins
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import VueSweetalert2 from 'vue-sweetalert2';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import VModal from 'vue-js-modal';
import Multiselect from 'vue-multiselect';
import VueTippy from "vue-tippy";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
import Notifications from 'vue-notification';


import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false;

window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;


// API service init
ApiService.init();
Vue.use(VueSweetalert2);
Vue.use(filter);
Vue.use(VuePlyr, {
    plyr: {}
});
Vue.use(CoolLightBox);
Vue.use(VModal);
Vue.component('multiselect', Multiselect);
Vue.use(VueTippy);
Vue.use(Notifications);

const loadimage = require('../public/media/icon/LOADING.gif')

Vue.use(VueLazyload,{
    preLoad: 1.3,
    lazyComponent: true,
    loading:loadimage,
  });

Vue.use(InfiniteLoading, { /* options */});

Vue.config.devtools = true;
Vue.config.disableNoTranslationWarning = true;

router.beforeEach((to, from, next) => {
    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

Vue.mixin({
    ...mixin,
});

getTranslations()
    .then(({data}) => {
        let i18n = prepareConfig(data);
        new Vue({
            router,
            store,
            i18n,
            vuetify,
            render: h => h(App)
        }).$mount("#app");
    });
